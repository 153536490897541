const env = process.env
const logotext = "home";
const meta = {
    title: "Aleksandar Nesovanovic",
};

const personalData = {
    name: env.REACT_APP_NAME,
    jobTitle: env.REACT_APP_JOB,
    company: env.REACT_APP_COMPANY,
    street: env.REACT_APP_STREET,
    zip: env.REACT_APP_ZIP,
    city: env.REACT_APP_CITY,
    mail: env.REACT_APP_MAIL,
    phone: env.REACT_APP_PHONE,
}

const introdata = {
    title: env.REACT_APP_NAME,
    animated: {
        first: "iOS/macOS-Entwicklung – Ihre Ideen, meine Expertise!",
        second: "Automatisierung – Effizienz steigern, Zeit sparen!",
        third: "Projektmanagement – Struktur und Erfolg für Ihr Vorhaben!",
    },
    description: "In einer Welt, die sich ständig weiterentwickelt, ist es entscheidend, nicht nur Schritt zu halten, sondern die Führung zu übernehmen. Ihre digitale Präsenz zu transformieren und Ihr IT-Potenzial voll auszuschöpfen. Mit umfassender Erfahrung in der Entwicklung maßgeschneiderter Produkte bin ich der ideale Partner für die Realisierung Ihrer Projekte.",
    aboutme: "tbd",
    your_img_url: "https://profile-images.xing.com/images/e1ef7f1603c9fa6cad468bb5ae02f2e6-4/aleksandar-nesovanovic.1024x1024.jpg",
};

const dataabout = {
    title: "Wer bin ich",
    aboutme: "Hallo! Ich bin ein passionierter Entwickler mit Schwerpunkt auf SwiftUI. Als begeisterter Scrum-Master und Projektleiter bringe ich fundierte Kenntnisse in Projektmanagement und Projektleitung mit. Darüber hinaus spezialisiere ich mich auf die Automatisierung von Prozessen und die Erstellung von Bots, um Effizienz und Produktivität zu steigern. Mit meiner Erfahrung und Leidenschaft für Technologie und agile Methoden setze ich Projekte erfolgreich um und unterstütze Teams dabei, ihre Ziele zu erreichen.",
};
const worktimeline = [{
        jobtitle: "Sernior Consultant",
        where: "Cassini Consulting",
        date: "2023 - ",
    },
    {
        jobtitle: "Head of Software Development",
        where: "Intergermania Transport GmbH",
        date: "2021 - 2023",
    },
    {
        jobtitle: "Designer of week",
        where: "ALquds",
        date: "2019",
    },
];

const skills = [{
        name: "Swift/SwiftUI",
        value: 100,
    },
    {
        name: "Python",
        value: 80,
    },
    {
        name: "ReactJS / Typescript",
        value: 75,
    },
    {
        name: "NodeJS",
        value: 80,
    },
];

const services = [{
    id: 1,
        title: "iOS / iPadOS / MacOS Apps",
        description: "Nutzen Sie das volle Potenzial der Apple-Plattformen mit unseren maßgeschneiderten iOS, iPadOS und macOS Apps. Wir entwickeln benutzerfreundliche und leistungsstarke Anwendungen, die perfekt auf Ihre Bedürfnisse abgestimmt sind. Ob Sie eine App für den App Store, ein Unternehmens-Tool oder eine spezielle Lösung benötigen, wir bieten Ihnen innovative und zuverlässige Softwarelösungen, die nahtlos in das Apple-Ökosystem integriert sind.",
    },
    {
        id: 2,
        title: "Web Apps",
        description: "Bringen Sie Ihr Unternehmen online mit unseren modernen und responsiven Web Apps. Wir erstellen maßgeschneiderte Webanwendungen, die auf allen Geräten und Browsern hervorragend funktionieren. Von einfachen Webseiten bis hin zu komplexen webbasierten Anwendungen bieten wir Ihnen Lösungen, die Ihre Geschäftsprozesse optimieren und Ihre Kunden begeistern werden. Lassen Sie uns gemeinsam Ihre Vision in die digitale Realität umsetzen.",
    },
    {
        id: 3,
        title: "Prozessautomatisierung / Roboter / Bots",
        description: "Steigern Sie die Effizienz und Produktivität Ihres Unternehmens durch unsere Lösungen zur Prozessautomatisierung. Wir entwickeln maßgeschneiderte Roboter und Bots, die repetitive Aufgaben automatisieren und so Zeit und Ressourcen sparen. Unsere intelligenten Automatisierungslösungen helfen Ihnen, Arbeitsabläufe zu optimieren, Fehler zu reduzieren und sich auf das Wesentliche zu konzentrieren. Entdecken Sie das Potenzial der Automatisierung und transformieren Sie Ihre Geschäftsprozesse mit uns.",
    },
];

const dataportfolio = [{
    projekt: "Organisationsaufbau sowie Prozessoptimierung und -digitalisierung zur behördenübergreifenden Digitalkollaboration",
    rolle: "Technischer Projektleiter",
    kunde: "Ministerium auf Bundesebene",
    beschreibung: "tbd",
    frameworks: ["NodeJS", "React"],
  },
  {
    projekt: "Entwicklung eines speziellen und eigenen TMS",
    rolle: "Head of Software Development",
    kunde: "Logistik & Transport Unternehmen",
    beschreibung: "tbd",
    frameworks: ["Scrum", "SwiftUI", "Docker", "CI/CD", "Gitlab"],
  },
  {
    projekt: "Entwicklung meherer Bots zur Prozessautomatisierung",
    rolle: "Entwickler",
    kunde: "Rocket Promotios",
    beschreibung: "tbd",
    frameworks: ["Python"],
  },
  {
    projekt: "Entwicklung eines ERPs zur Verarbeitung von Marktinganfragen",
    rolle: "Projektleiter",
    kunde: "Unternehmen in der Automobilbranche",
    beschreibung: "tbd",
    frameworks: ["NextJS", "NestJS", "Typescript", "CI/CD", "Gitlab", "Srum"],
  },
  {
    projekt: "Weiterentwicklung der Sparkassen App für iOS & Android",
    rolle: "Testmanger",
    kunde: "StarFinanz GmbH",
    beschreibung: "tbd",
    frameworks: ["Java", "Swift/SwiftUI", "Bitbucket", "Jenkins", "Srum"],
  }
];

const contactConfig = {
    YOUR_EMAIL: env.REACT_APP_MAIL,
    YOUR_FONE: env.REACT_APP_PHONE,
    description: "Ihr Projekt verdient es, mit Leidenschaft und Expertise umgesetzt zu werden. Wenn Sie auf der Suche nach einer maßgeschneiderten Softwarelösung sind, freue ich mich auf Ihre Nachricht.",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: env.REACT_APP_YOUR_SERVICE_ID,
    YOUR_TEMPLATE_ID: env.REACT_APP_YOUR_TEMPLATE_ID,
    YOUR_USER_ID: env.REACT_APP_YOUR_USER_ID,
};

const socialprofils = {
    github: "https://github.com/novicsoft",
    linkedin: "https://www.linkedin.com/in/aleksandar-nesovanovic-22096513b/",
    xing: "https://www.xing.com/profile/Aleksandar_Nesovanovic",
    gitlab: "https://gitlab.com/novicsoft",
    scrum: "https://scrum.org"
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
    personalData
};
