import React, { useEffect, useState } from "react";

const Themetoggle = () => {
  const theme = "light"
  
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme ); 
  }, [theme]);
  return (
    <div/>
  );
};

export default Themetoggle;
